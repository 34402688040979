<template>
  <v-container v-if="!pageLoading" class="my-16">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-list
              :color="statusFind('siparisDurum', detail.head.durum, 'color')"
              rounded
              dense
              class="px-0"
              style="height: 100%"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text"> Sipariş Durumu </v-list-item-subtitle>
                  <div class="white--text text-body-1" style="line-height: 1.2">
                    {{ statusFind('siparisDurum', detail.head.durum, 'text') }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-list color="success" rounded dense class="px-0" style="height: 100%">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="white">
                    {{ icons.mdiCalendar }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text"> Sipariş Tarihi </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ detail.head.siparis_tarihi }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-list color="success" rounded dense class="px-0" style="height: 100%">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="white">
                    {{ icons.mdiContactlessPayment }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text"> Ödeme </v-list-item-subtitle>
                  <v-list-item-title v-if="detail.head.odeme_tipi === '1'" class="white--text mb-2">
                    Kredi Kartı
                  </v-list-item-title>
                  <v-list-item-title v-if="detail.head.odeme_tipi === '2'" class="white--text mb-2">
                    Banka Havalesi
                  </v-list-item-title>
                  <v-list-item-title v-if="detail.head.odeme_tipi === '2'" class="white--text">
                    <v-chip v-if="detail.head.banka_adi" label x-small color="white">
                      <span class="black--text">
                        {{ detail.head.banka_adi }}
                      </span>
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-list color="success" rounded dense class="px-0" style="height: 100%">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="white">
                    {{ icons.mdiTruckFast }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text"> Teslim Tarihi </v-list-item-subtitle>
                  <v-list-item-title class="white--text mb-2">
                    {{ detail.head.siparis_tarihi.slice(0, 10) }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-chip label x-small color="white">
                      <span class="black--text">
                        {{ detail.head.kargo_bilgisi.kargo_adi }}
                      </span>
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="10">
        <v-card color="grey lighten-1" rounded="lg">
          <v-card-title>
            <v-avatar color="nightDark" class="mr-3">
              <v-icon :size="24">
                {{ icons.mdiFormatListText }}
              </v-icon>
            </v-avatar>

            <h3 class="text-h6" style="line-height: 1">Sipariş Listesi</h3>
            <v-spacer></v-spacer>
            <v-chip label> Sipariş Numarası : {{ detail.head.id }} </v-chip>
          </v-card-title>
          <v-card-text>
            <v-list color="transparent" dense class="pa-0">
              <div v-for="(line, index) in detail.products" :key="`LIST-${index}`">
                <v-hover v-slot="{ hover }">
                  <v-list-item
                    :three-line="!$vuetify.breakpoint.mdAndUp"
                    :two-line="$vuetify.breakpoint.mdAndUp"
                    :class="hover ? 'nightDark' : null"
                    class="py-2 py-md-0 px-0 px-md-2"
                  >
                    <v-list-item-avatar class="mx-0 mx-md-3" :size="$vuetify.breakpoint.mdAndUp ? 75 : 50">
                      <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 75 : 50" tile>
                        <v-img
                          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                          :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                          :src="
                            line.resim
                              ? 'https://portalapi.mesan.com.tr/' + line.resim
                              : require('@/assets/images/misc/not-image.png')
                          "
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-action>
                      <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 120 : 80" color="transparent">
                        <CounterTo
                          :count="false"
                          :uid="line.uid"
                          :plant-id="line.selected_plant"
                          :miktar="line.qty"
                          :birim="line.birim"
                          :birim-ritim="line.birim_ritmi"
                          bg-color="counter"
                          button-color="black"
                          :light="true"
                          @refreshBasket="refreshBasket()"
                        />
                      </v-sheet>
                    </v-list-item-action>
                    <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-2'">
                      <div>
                        <v-chip x-small label color="secondary"> Konya </v-chip>
                      </div>
                      <v-list-item-title
                        class="text-caption text-md-body-2 font-weight-bold pt-1"
                        :style="$vuetify.breakpoint.mdAndUp ? null : 'white-space: normal !important'"
                      >
                        {{ line.baslik }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="mt-1">
                        {{ line.total_price | currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-hover>
                <v-divider></v-divider>
              </div>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right"> Nakliye Ücreti </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.kargo_ucreti | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right">
                    <p class="pa-0 ma-0">Toplam Tutarı</p>
                    <small class="text--disabled">(KDV Dahil)</small>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.total_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right"> KDV </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.tax_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-alert :outlined="isDark" color="warning" class="rounded-lg" height="100%">
          <v-card color="transparent" flat rounded="lg" height="100%">
            <v-card-title>
              <v-icon :color="isDark ? 'warning' : 'white'" class="mr-3">
                {{ icons.mdiMapMarker }}
              </v-icon>

              <div class="text-h6" :class="isDark ? 'warning--text' : 'white--text'">Teslimat Adresi</div>
              <v-spacer></v-spacer>
              <v-chip small :color="isDark ? 'warning' : 'white'" :outlined="!isDark" class="font-weight-light">
                {{ detail.head.teslimat_adresi.address_title }}
              </v-chip>
            </v-card-title>

            <v-card-text>
              <v-list class="pa-0" color="transparent">
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle :class="isDark ? 'warning--text' : 'white--text'">
                      Alıcı Adı
                    </v-list-item-subtitle>
                    <div class="white--text" style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>
                <v-list-item three-line class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle :class="isDark ? 'warning--text' : 'white--text'">
                      Adres
                    </v-list-item-subtitle>
                    <div class="white--text" style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.address }} {{ detail.head.teslimat_adresi.state }} /
                      {{ detail.head.teslimat_adresi.city_txt }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>

                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle :class="isDark ? 'warning--text' : 'white--text'">
                      Alıcı Telefonu
                    </v-list-item-subtitle>
                    <div class="white--text" style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.phone }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-alert>
      </v-col>
      <v-col cols="12" md="5">
        <v-alert :outlined="isDark" color="secondary" height="100%">
          <v-card color="transparent" flat rounded="lg" height="100%">
            <v-card-title>
              <v-icon :color="isDark ? 'secondary' : 'white'" class="mr-3">
                {{ icons.mdiReceipt }}
              </v-icon>

              <div class="text-h6" :class="isDark ? 'secondary--text' : 'white--text'">Fatura Bilgileri</div>

              <v-spacer></v-spacer>
              <v-chip small :color="isDark ? 'secondary' : 'white'" :outlined="!isDark" class="font-weight-light">
                Kurumsal
              </v-chip>
            </v-card-title>

            <v-card-text>
              <v-list class="pa-0" color="transparent">
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle :class="isDark ? 'secondary--text' : 'white--text'">
                      Fatura Adı
                    </v-list-item-subtitle>
                    <div class="white--text" style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.fatura_adresi.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>
                <v-list-item three-line class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle :class="isDark ? 'secondary--text' : 'white--text'">
                      Adres
                    </v-list-item-subtitle>
                    <div class="white--text" style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.address }} {{ detail.head.teslimat_adresi.state }} /
                      {{ detail.head.teslimat_adresi.city_txt }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle :class="isDark ? 'secondary--text' : 'white--text'">
                      Vergi Dairesi / Numarası
                    </v-list-item-subtitle>
                    <div class="white--text" style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.state }} / {{ detail.head.teslimat_adresi.mobile }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="detail.head.buyer_note !== 'null'" justify="center">
      <v-col cols="12" md="10">
        <v-alert text outlined color="error"> Not : {{ detail.head.buyer_note }} </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'

import CounterTo from '@/components/CounterTo.vue'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import { useRouter } from '@core/utils'
import {
  mdiCalendar,
  mdiCheckBold,
  mdiContactlessPayment,
  mdiFormatListText,
  mdiMapMarker,
  mdiQrcodeScan,
  mdiReceipt,
  mdiTruckFast,
} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  components: {
    CounterTo,
  },

  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const detail = ref({})
    const pageLoading = ref(true)
    const { router } = useRouter()
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]
    const { isDark } = useAppConfig()

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    onMounted(() => {
      if (!router.currentRoute.params.id) {
        router.push({ name: 'order-tracking' })
      }
      const { id } = router.currentRoute.params

      // const id = 11096
      const method = 'getOrderDetail'
      store.dispatch('postMethod', { method, id }).then(response => {
        detail.value = response.detail
        pageLoading.value = false
      })
    })
    const siparisToplamTutar = computed(
      () =>
        Number(detail.value.head?.kargo_ucreti) +
        Number(detail.value.head?.tax_price) +
        Number(detail.value.head?.total_price),
    )

    return {
      siparisToplamTutar,
      statusFind,
      pageLoading,
      detail,
      isDark,
      icons: {
        mdiCalendar,
        mdiFormatListText,
        mdiReceipt,
        mdiMapMarker,
        mdiCheckBold,
        mdiQrcodeScan,
        mdiTruckFast,
        mdiContactlessPayment,
      },

      // Icons
    }
  },
}
</script>

<style></style>
